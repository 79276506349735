import React, { useEffect, useState } from "react";
import icons from "../../assets/icons/icons";
import color from "../../assets/colors";
import { connect } from "react-redux";
import config from "../../config/config";
import Link from "next/link";
import { useRouter } from "next/router";

const NavigationGroup = ({
  menuToggler,
  RouterValue,
  menuStatus,
  onToggle,
}: any) => {
  const [searchTxt, setSearchTxt] = useState("");
  const history = useRouter();
  const [state, setstate] = useState({
    searchStatus: false,
  });
  const [openMenu, setOpenMenu] = useState(false)
  const { asPath } = useRouter();

  const windowSize = useWindowSize();
  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined,
    });
    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };
      if (window.innerWidth <= 768) {
        setstate({ searchStatus: false });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return dimension;
  }

  const onClear = () => {
    setstate({ searchStatus: false });
  };

  const onSearch = (event: any) => {
    setSearchTxt(event.target.value);
  };

  const onSubmit = () => {
    history.push(`/search/?${config.searchParam}=${searchTxt}`);
    setSearchTxt("");
  };

  const onKeyPress = (event: any) => {
    if (event.charCode === 13) {
      onSubmit();
    }
  };

  const handleOnMouseOver = () => {
    setOpenMenu(true)
  }

  const handleOnMouseLeave = () => {
    setOpenMenu(false)
  }

  const addProduct = (name, link) => {
    if(name === "ACCESSORIES"){
      return link
    }else{
      return `/product${link}`
    }
  }

  return (
    RouterValue && (
      <div className="NavigationGroup">
        {windowSize.width <= 768 && (
          <div onClick={menuToggler} className="flex-menu-icon">
            {!menuStatus && icons.menu(color.$white, "fa-sm")}
            {menuStatus && icons.close(color.$white, "")}
            <span>Menu</span>
          </div>
        )}
        {windowSize.width > 768 && RouterValue.Products && RouterValue.Products.length > 0 && (
          <div className="products-dropdown" onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave}>
            <a className={`${(asPath?.split("/")[1] === `product` || asPath?.split("/")[2] === `accessories`) && `active` } NavigationItem`}>
              <span style={{ marginRight: 5 }}> PRODUCTS </span>
              {icons.downArrow(color.$white, "fa-xs")}
            </a>
          {openMenu && <ul className="prod-sub-dropdown">
              {RouterValue.Products?.map((item: any, index: number) => (
                <li key={index}>
                  <Link href={addProduct(item.name, item.link)}>
                    <a className={`${`${asPath}/` === `${addProduct(item.name, item.link)}` && `active-sub-link ` } NavigationItem-a prod-sub-dropdown-a`}>
                      {item.name}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>}
          </div>
        )}
        {RouterValue.Routes &&
          RouterValue.Routes.length > 0 &&
          RouterValue.Routes.map((data: any, i: number) => {
            if (data.name && windowSize.width > 768 && !data.icon) {
              return (
                <Link key={i} href={data.link}>
                  <a
                    className={
                      asPath === data.link
                        ? "NavigationItem active"
                        : "NavigationItem"
                    }
                    style={{ backgroundColor: data.backgroundColor }}
                  >
                    {data.name}
                  </a>
                </Link>
              );
            } else if (data.icon === "shopping-cart") {
              return (
                <ol key={i} className="flex-cart-icon-ol">
                  <li
                    onClick={onToggle}
                    key={i}
                    className="flex-cart-icon"
                    tabIndex={0}
                  >
                    {icons.cart(color.$white, "fa-sm")}
                  </li>
                </ol>
              );
            } else if (data.icon === "search" && windowSize.width > 768) {
              return (
                <React.Fragment key={i}>
                  <ol>
                    <li
                      className={
                        "flex-search-box " +
                        (state.searchStatus ? "hide" : "show")
                      }
                      onClick={() => {
                        setstate({ searchStatus: true });
                      }}
                      tabIndex={0}
                    >
                      {icons.search(color.$white, "fa-sm")}
                    </li>
                  </ol>
                  {windowSize.width > 768 && state.searchStatus && (
                    <React.Fragment>
                      <input
                        type="text"
                        autoComplete="off"
                        name="search"
                        value={searchTxt}
                        onChange={onSearch}
                        onKeyPress={onKeyPress}
                      />
                      <span
                        className="flex-clear-search"
                        onClick={() => {
                          onClear();
                        }}
                      >
                        {icons.close(color.$white, "")}
                      </span>
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            }
            return null;
          })}
      </div>
    )
  );
};

const mapDispatch = (dispatch: any) => {
  return {
    onToggle: () => dispatch({ type: "TOGGLE_CART" }),
  };
};

export default connect(null, mapDispatch)(NavigationGroup);
