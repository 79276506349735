import Head from 'next/head';
import { useState } from 'react';
import { useEffect } from 'react';
import config from './config';

const SetHeaders = ({ HEADERS }: any) => {
  const wl = useWindowLocation()
  function useWindowLocation() {
    const [winLocation, setWinLocation] = useState({
      location: undefined
    })
    useEffect(() => {
      const handleLocation = () => {
        setWinLocation({
          location: window.location.href
        })
      }
      handleLocation()
    }, [HEADERS]);
    return winLocation
  }

  const getTags = () => {
    const head = [
      { key: 'metaDescription', title: 'description' },
      { key: 'metaTitle', title: 'title' },
      { key: 'keywords', title: 'keywords' },
      {
        key: 'ogTitle',
        title: 'og:title'
      },
      { key: 'ShareImage', title: 'og:image' },
      { key: 'structuredData' },
      { key: 'og:site_name', title: 'og:site_name', data: 'Nexersys' },
      { key: 'article:modified_time', title: 'article:modified_time' },
      { key: 'og:url', title: 'url' },
      { key: 'og:locale', title: 'og:locale', data: 'en_US' }
    ];

    return head.map((item: any, i: number) => {
      switch (item.key) {
        case 'metaTitle':
          return HEADERS.Seo[item.key] && <title key={i}>{HEADERS.Seo[item.key]}</title>;
        case 'ShareImage':
          return (
            HEADERS.Seo[item.key] &&
            HEADERS.Seo[item.key].media &&
            HEADERS.Seo[item.key].media.url && (
              <meta content={config.baseUrl + HEADERS.Seo[item.key].media.url} key={i} property={item.title} />
            )
          );
        case 'structuredData':
          const structuredData = JSON.stringify(HEADERS.Seo[item.key]);
          return (
            structuredData && (
              <script type="application/ld+json" key={i}>
                {structuredData}
              </script>
            )
          );
        case 'og:site_name':
          return <meta property={item.key} key={i} content={item.data} />;
        case 'og:locale':
          return <meta property={item.key} key={i} content={item.data} />;
        case 'og:url':
          return <meta property={item.key} key={i} content={wl.location} />;
        case 'article:modified_time':
          return HEADERS.updated_at && <meta property={item.key} key={i} content={HEADERS.updated_at} />;
        default:
          if (item.property) {
            return HEADERS.Seo[item.key] && <meta content={HEADERS.Seo[item.key]} key={i} property={item.title} />;
          } else {
            return HEADERS.Seo[item.key] && <meta content={HEADERS.Seo[item.key]} key={i} name={item.title} />;
          }
      }
    });
  };
  return HEADERS && HEADERS.Seo ? <Head>{getTags()}</Head> : null;
};

export default SetHeaders;
