import NavigationGroup from "../Navigation/NavigationGroup";
import API from "../../config/config";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

const Header = ({ HeaderValue, history }: any) => {
  const [state, setstate] = useState({
    menu: false,
  });
  const [openNav, setOpenNav] = useState(false)
  const { asPath } = useRouter();

  var keyId = 0;
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) setstate({ menu: false });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if(!state.menu){
      setOpenNav(false)
    }
  },[state])

  const menuToggler = () => {
    setstate({ menu: !state.menu });
  };

  const handleOpenNav = () => {
    setOpenNav(prev => !prev)
  }

  const handleCloseNav = () => {
    setstate({ menu: false });
    setOpenNav(false)
  }

  const addProduct = (name, link) => {
    if(name === "ACCESSORIES"){
      return link
    }else{
      return `/product${link}`
    }
  }
  
  return (
    HeaderValue && (
      <React.Fragment>
        <header key={keyId++} className="app-header">
          <Link href="/">
            <a className="Logo">
              <div className="logo-container">
                <img
                  src={API.baseUrl + HeaderValue.logo.url}
                  alt={HeaderValue.logo.alternativeText}
                />
              </div>
            </a>
          </Link>
          <nav key={keyId++} className="nav">
            <NavigationGroup
              RouterValue={HeaderValue}
              menuToggler={menuToggler}
              menuStatus={state.menu}
              history={history}
            />
          </nav>
        </header>
        {state.menu && window.innerWidth <= 768 && (
          <ul className="menu-items">
            {HeaderValue.Products && HeaderValue.Products.length > 0 && (
              <>
                <a
                  className={`item prod-nav ${openNav && `prod-products-open`} ${(asPath?.split("/")[1] === `product` || 
                  asPath?.split("/")[2] === `accessories`) && `active` }
                  }`}
                  style={{padding:0}}
                  // className={
                  //   asPath === "PRODUCTS"
                  //     ? "NavigationItem active"
                  //     : "NavigationItem"
                  // }
                  // style={{ backgroundColor: data.backgroundColor }}
                >
                  <div className="prod-items">
                  <span style={{ marginRight: 5 }}> PRODUCTS </span>
                  <i className="fas fa-chevron-down fa-xs" aria-hidden="true" onClick={handleOpenNav}></i>
                  </div>
                  {openNav && HeaderValue.Products?.map((item: any, index: number) => (
                  <Link key={index} href={addProduct(item.name, item.link)}>
                    <a
                      className={`prod-items prod-items-a ${`${asPath}/` === `${addProduct(item.name, item.link)}` && `active-sub-link` }`} onClick={handleCloseNav}>
                      {item.name}
                    </a>
                  </Link>
                ))}
                </a>
              </>
            )}
            {HeaderValue.Routes &&
              HeaderValue.Routes.length > 0 &&
              HeaderValue.Routes.map((item: any, key: number) => {
                if (!item.icon) {
                  return (
                    <Link key={key} href={item.link}>
                      <a className="item"> {item.name}</a>
                    </Link>
                  );
                }
                return null;
              })}
          </ul>
        )}
      </React.Fragment>
    )
  );
};

export default Header;
